import { useEffect, useRef, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { menu } from "../../js/menu";

function Header(props) {
    const interval = useRef(null);
    let scrollPosition = 0;
    const [didScroll, setDidScroll] = useState(null);
    const [lastScrollTop, setlastscrollTop] = useState(0);
    const [navClass, setNavClass] = useState("nav-down");
    const delta = 5;
    const updateScroll = () => {
        scrollPosition = window.scrollY || document.documentElement.scrollTop;
    };

    function tick() {
        if (didScroll) {
            hasScrolled();
            setDidScroll(false);
        }
    }

    function hasScrolled() {
        if (Math.abs(lastScrollTop - scrollPosition) <= delta) {
            return;
        }

        if (scrollPosition > lastScrollTop && scrollPosition > 104) {
            setNavClass("nav-up");
        } else {
            if (scrollPosition + window.innerHeight < document.body.scrollHeight) {
                setNavClass("nav-down");
            }
        }

        setlastscrollTop(scrollPosition);
    }

    const redirectOnlineAccount = () => {
        window.open(
            "https://unionservice.intravan.co.kr/View/Intravan/AccountLogin.aspx",
            "",
            "width=920, height=740, top=5, left=30, toolbar=no, maximize=yes, resize=yes, location=no, directories=no, scrollbars=yes, status=no"
        );
        return false;
    };

    useEffect(() => {
        //메인인 경우에만 Sticky
        if (props.page === "main") {
            window.addEventListener("scroll", updateScroll);
            window.addEventListener("scroll", () => {
                setDidScroll(true);
            });

            interval.current = setInterval(tick, 250);
            return () => {
                clearInterval(interval.current);
            };
        }
    });

    return (
        <header className={`${navClass} ${props.page}`}>
            <Navbar expand="lg" className="navbar-expand-lg navigation" id="navbar">
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        {props.page === "main" ? (
                            <img
                                src="/assets/intravan_logo.png"
                                alt=""
                                className="img-fluid img-logo"
                            />
                        ) : (
                            <img
                                src="/assets/intravan-logo-footer.png"
                                alt=""
                                className="img-fluid img-logo"
                            />
                        )}
                    </Navbar.Brand>

                    <Navbar.Toggle
                        className={
                            props.page === "main"
                                ? "collapsed main-toggle"
                                : "collapsed sub-toggle"
                        }
                        data-toggle="collapse"
                        data-target="#navbarmain"
                        aria-controls="navbarmain"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="fas fa-bars"></span>
                    </Navbar.Toggle>

                    <Navbar.Collapse className="navbar-collapse" id="navbarmain">
                        <Nav className="ml-auto">
                            <NavDropdown
                                title={<span className={props.page}>{menu[0].title}</span>}
                                renderMenuOnMount={true}
                                id="menu01"
                            >
                                {menu[0].sub.map((item, idx) => {
                                    return (
                                        <NavDropdown.Item as={Link} to={item.url} key={idx}>
                                            {item.subTitle || ""}
                                            &nbsp;&nbsp;
                                            {item.badge ? (
                                                <span className="badge badge-primary">
                                                    {item.badge}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </NavDropdown.Item>
                                    );
                                })}
                            </NavDropdown>

                            <NavDropdown
                                title={<span className={props.page}>{menu[1].title}</span>}
                                renderMenuOnMount={true}
                                id="menu02"
                            >
                                {menu[1].sub.map((item, idx) => {
                                    return (
                                        <NavDropdown.Item as={Link} to={item.url} key={idx}>
                                            {item.subTitle || ""}
                                            &nbsp;&nbsp;
                                            {item.badge ? (
                                                <span className="badge badge-secondary">
                                                    {item.badge}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </NavDropdown.Item>
                                    );
                                })}
                            </NavDropdown>

                            <NavDropdown
                                title={<span className={props.page}>{menu[2].title}</span>}
                                renderMenuOnMount={true}
                                id="menu03"
                            >
                                {menu[2].sub.map((item, idx) => {
                                    return (
                                        <NavDropdown.Item as={Link} to={item.url} key={idx}>
                                            {item.subTitle || ""}
                                            &nbsp;&nbsp;
                                            {item.badge ? (
                                                <span className="badge badge-secondary">
                                                    {item.badge}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </NavDropdown.Item>
                                    );
                                })}
                            </NavDropdown>

                            <NavDropdown
                                title={<span className={props.page}>{menu[3].title}</span>}
                                renderMenuOnMount={true}
                                id="menu04"
                            >
                                {menu[3].sub.map((item, idx) => {
                                    return (
                                        <NavDropdown.Item as={Link} to={item.url} key={idx}>
                                            {item.subTitle || ""}
                                            &nbsp;&nbsp;
                                            {item.badge ? (
                                                <span className="badge badge-secondary">
                                                    {item.badge}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </NavDropdown.Item>
                                    );
                                })}
                            </NavDropdown>

                            <Link
                                to="/Question"
                                className={`top-menu ${props.page}`}
                                id="menu05"
                            >
                                제품문의
                            </Link>

                            {localStorage.getItem("comcode") ? (
                                <NavDropdown
                                    title={
                                        <span className={props.page}>
                                            고객센터 <i className="icofont-thin-down"></i>
                                        </span>
                                    }
                                    renderMenuOnMount={true}
                                    id="menu06"
                                >
                                    <NavDropdown.Item as={Link} to="/NationWideNetwork">
                                        전국 서비스망 네트워크
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/CsCenter">
                                        고객센터
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/Faq">
                                        FAQ (자주하는질문)
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/News">
                                        업계이슈
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/Upgrade">
                                        신규기능소개
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={redirectOnlineAccount}>
                                        온라인장부
                                    </NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <NavDropdown
                                    title={
                                        <span className={props.page}>
                                            고객센터 <i className="icofont-thin-down"></i>
                                        </span>
                                    }
                                    renderMenuOnMount={true}
                                    id="menu06"
                                >
                                    <NavDropdown.Item as={Link} to="/NationWideNetwork">
                                        전국 서비스망 네트워크
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/CsCenter">
                                        고객센터
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/News">
                                        업계이슈
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={redirectOnlineAccount}>
                                        온라인장부
                                    </NavDropdown.Item>
                                </NavDropdown>
                            )}

                            <NavDropdown
                                title={
                                    <span className={props.page}>
                                        회사소개 <i className="icofont-thin-down"></i>
                                    </span>
                                }
                                renderMenuOnMount={true}
                                id="menu07"
                            >
                                <NavDropdown.Item as={Link} to="/Intro">
                                    회사소개
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/History">
                                    회사연혁
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/InfoList">
                                    공지사항
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/Award">
                                    인트라밴 발자취
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/Direction">
                                    찾아 오시는 길
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/Partner">
                                    업무제휴
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
