import { useState } from "react"
import CompPrivacyPolicy241216 from "./CompPrivacyPolicy241216";
import CompPrivacyPolicy241217 from "./CompPrivacyPolicy241217";

export default function CompPrivacyPolicy({ style }) {
    const [selectedIndex, setSelectedIndex] = useState("0");
    return (
        <>
            <div className={style?.normalTypography}>
                (주)인트라밴 하이웨이서비스는 정보주체의 자유와 권리 보호를 위해 [개인정보보호법] 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고
                안전하게 관리하고 있습니다. 이에 [개인정보보호법] 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하며 법률 및 지침 변경이나
                (주)인트라밴 하이웨이서비스의 내부 방침 변경 등으로 인하여 필요한 경우 변경 될 수 있고, 개인정보 취급방침을 개정하는 경우 (주)인트라밴 하이웨이서
                비스는 그 변경사항과 개정일자에 대하여 즉시 홈페이지를 통하여 게시하여 고객들이 쉽게 알아볼 수 있도록 하고 있습니다.
            </div>
            <div className={style?.gridContainer}>
                <div className={style?.gridItem}>
                    <img src="./assets/icon/icon01.png" alt="" />
                    <span>개인정보 수집</span>
                </div>
                <div className={style?.gridItem}>
                    <img src="./assets/icon/icon02.png" alt="" />
                    <span>개인정보 수집 목적</span>
                </div>
                <div className={style?.gridItem}>
                    <img src="./assets/icon/icon03.png" alt="" />
                    <span>제 3자 제공</span>
                </div>
                <div className={style?.gridItem}>
                    <img src="./assets/icon/icon04.png" alt="" />
                    <span>처리위탁</span>
                </div>
                <div className={style?.gridItem}>
                    <img src="./assets/icon/icon05.png" alt="" />
                    <span>개인정보 파기</span>
                </div>
                <div className={style?.gridItem}>
                    <img src="./assets/icon/icon06.png" alt="" />
                    <span>법정 대리인</span>
                </div>
                <div className={style?.gridItem}>
                    <img src="./assets/icon/icon07.png" alt="" />
                    <span>안정성확보조치</span>
                </div>
                <div className={style?.gridItem}>
                    <img src="./assets/icon/icon08.png" alt="" />
                    <span>개인정보 관리 책임</span>
                </div>
            </div>
            <div>(주)인트라밴 하이웨이서비스의 개인정보 취급방침은 다음과 같은 내용을 담고 있습니다.</div>
            <div>
                <select
                    className="form-control form-select"
                    value={selectedIndex}
                    onChange={(e) => setSelectedIndex(e.target.value)}>
                    <option value="0">2024-12-17 이후</option>
                    <option value="1">2024-12-16 이전</option>
                </select>
            </div>
            {
                selectedIndex === "0" ? <CompPrivacyPolicy241217 style={style} /> : null
            }
            {
                selectedIndex === "1" ? <CompPrivacyPolicy241216 style={style} /> : null
            }
        </>
    )
}