const escapeHTML = function (s) {
    if (s) {
        return s.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
    }
};

const wrapHTML = function (s) {
    if (s) {
        return s.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&");
    }
};

// css class 결합 함수
export function cls(...classnames) {
    return classnames.join(" ");
}

//파일 다운로드
const fnFileDownload = (fileUrl, fileName) => {
    fetch(fileUrl).then((response) => {
        response.blob().then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.click();
        });
    });
};

const numericComma = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));

const fnNotLogin = () => {
    window.location.href = "/";
};

const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
        element.scrollIntoView({ behavior: "smooth" });
    }
};

export {
    cloneObj,
    escapeHTML,
    wrapHTML,
    fnFileDownload,
    numericComma,
    fnNotLogin,
    handleScroll
};
