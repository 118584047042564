import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";

import ScrollToTop from "./comp/comm/ScrollToTop";
import Page404 from "./comp/comm/Page404";
import Loading from "./comp/comm/Loading";
import Footer from "./comp/comm/Footer";

// 회사소개
import Intro from "./comp/intro/Intro";
import History from "./comp/intro/History";
import Award from "./comp/intro/Award";
import Partner from "./comp/intro/Partner";
import Direction from "./comp/intro/Direction";
import NationWideNetwork from "./comp/intro/NationWideNetwork";
import MainContents from "./comp/MainContents";

// 제품소개
import Prod01 from "./comp/prod/Prod01";
import Prod02 from "./comp/prod/Prod02";
import Prod03 from "./comp/prod/Prod03";
import Prod04 from "./comp/prod/Prod04";
import Prod05 from "./comp/prod/Prod05";
import Prod06 from "./comp/prod/Prod06";
import Prod07 from "./comp/prod/Prod07";

// 제품문의
import Question from "./comp/question/Question";

// 고객센터
import Faq from "./comp/cscenter/Faq";
import InfoList from "./comp/cscenter/InfoList";
import CsCenter from "./comp/cscenter/CsCenter";
import News from "./comp/cscenter/News";
import Upgrade from "./comp/cscenter/Upgrade";

// 차량식별정보 체험페이지
import VinDecoder from "./comp/vindecoder/VinDecoder";

// 아델 차량식별정보 체험페이지
import SvcVinDecoder from "./comp/VinDecoderRelease/VinDecoder";

//기타페이지
import Stats from "./comp/stats/StatsView"; //솔루션 통계페이지

//이용약관 및 개인정보취급정책
import TermsOfService from "./globals/TermsOfService";

import { useEffect } from "react";
let currentPath = "";

function App(props) {
    const store = useSelector((state) => state);
    const history = useHistory();
    let location = useLocation();

    useEffect(
        () => {
            ReactGA.initialize("UA-141101699-5");
            history.listen((location) => {
                ReactGA.set({ page: location.pathname });
                ReactGA.pageview(location.pathname);
            });
        },
        // eslint-disable-next-line
        []
    );

    useEffect(() => {
        if (currentPath === location.pathname) window.location.reload();

        currentPath = location.pathname;
    }, [location]);

    return (
        <div className="App">
            <Loading loading={store.rdcLoading} />

            <ScrollToTop>
                <Switch>
                    <Route exact path="/" component={MainContents} />
                    {/* 종합 소형(1,2급) 정비공장용 프로그램 */}
                    <Route path="/Prod01" component={Prod01} />
                    {/* 견적관리 프로그램 */}
                    <Route path="/Prod02" component={Prod02} />
                    {/* 전문정비(3급)용 프로그램 */}
                    <Route path="/Prod03" component={Prod03} />
                    {/* 체인점용 프로그램 */}
                    <Route path="/Prod04" component={Prod04} />
                    {/* 모바일 앱 */}
                    <Route path="/Prod05" component={Prod05} />
                    {/* 부가서비스 */}
                    <Route path="/Prod06" component={Prod06} />
                    {/* 협단체서비스 */}
                    <Route path="/Prod07" component={Prod07} />
                    {/* 회사소개 */}
                    <Route path="/Intro" component={Intro} />
                    {/* 회사연혁 */}
                    <Route path="/History" component={History} />
                    {/* 언론 보도 및 수상 */}
                    <Route path="/Award" component={Award} />
                    {/* 찾아오시는 길 */}
                    <Route path="/Direction" component={Direction} />
                    {/* 찾아오시는 길 */}
                    <Route path="/NationWideNetwork" component={NationWideNetwork} />
                    {/* 업무제휴 */}
                    <Route path="/Partner" component={Partner} />
                    {/* 제품문의 */}
                    <Route path="/Question" component={Question} />
                    {/* 자주하는질문 */}
                    <Route path="/Faq" component={Faq} />
                    {/* 공지사항 */}
                    <Route path="/InfoList" component={InfoList} />
                    {/* 업계이슈 */}
                    <Route path="/News" component={News} />
                    {/* 신규기능소개 */}
                    <Route path="/Upgrade" component={Upgrade} />
                    {/* 비회원 고객센터 */}
                    <Route path="/CsCenter" component={CsCenter} />
                    {/* 솔루션 통계페이지 */}
                    <Route path="/Stats" component={Stats} />
                    {/* 차량식별정보 체험페이지 */}
                    <Route path="/VinDecoder" component={VinDecoder} />
                    {/* 아델 체험페이지 */}
                    <Route path="/vin-decoder/release" component={SvcVinDecoder} />
                    {/* 개인정보취급정책 및 이용약관 */}
                    <Route path="/terms-of-service" component={TermsOfService} />

                    {/* 견적의뢰 component 끝 */}
                    {/* 없는 페이지 */}
                    <Route path="/:id" component={Page404} />
                </Switch>
            </ScrollToTop>

            <Footer />
        </div>
    );
}

export default App;
