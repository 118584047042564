import { handleScroll } from "../../js/util";

export default function CompPrivacyPolicy241217({ style }) {
    return (
        <>
            <ul className={style.wrapperIndex}>
                <li onClick={() => handleScroll("section1")}>1. 개인정보 수집에 대한 동의</li>
                <li onClick={() => handleScroll("section2")}>2. 개인정보의 처리 및 이용목적</li>
                <li onClick={() => handleScroll("section3")}>3. 개인정보 항목 및 수집방법</li>
                <li onClick={() => handleScroll("section4")}>4. 개인정보의 보유 및 이용기간</li>
                <li onClick={() => handleScroll("section5")}>5. 개인정보의 파기 절차 및 방법</li>
                <li onClick={() => handleScroll("section6")}>6. 개인정보의 보호 및 관리</li>
                <li onClick={() => handleScroll("section7")}>7. 이용자 및 법정대리인의 권리와 행사 방법</li>
                <li onClick={() => handleScroll("section8")}>8. 만 14세미만 아동회원의 가입에 대한 법정대리인의 동의 관련 사항</li>
                <li onClick={() => handleScroll("section9")}>9. 개인정보보호를 위한 노력(안전성 확보 조치)</li>
                <li onClick={() => handleScroll("section10")}>10. 쿠키(cookie)의 운영에 관한 사항</li>
                <li onClick={() => handleScroll("section11")}>11. 개인정보 관련 의견수렴 및 불만처리에 관한 사항</li>
                <li onClick={() => handleScroll("section12")}>12. 개인정보의 취급 위탁 및 제3자 제공</li>
                <li onClick={() => handleScroll("section13")}>13. 개인정보보호 책임자 및 연락처</li>
                <li onClick={() => handleScroll("section14")}>14. 개인정보침해 신고 및 상담 문의</li>
                <li onClick={() => handleScroll("section15")}>15. 개인정보 처리방침의 변경에 관한 사항</li>
            </ul>
            <div className={style.wrapperDepth}>
                <div id="section1" className={style.depth1}>
                    1. 개인정보 수집에 대한 동의
                </div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스는 이용자들이 (주)인트라밴 하이웨이서비스의 개인정보취급방침 또는 이용약관의 내용에 대하여 「동의」 또는 「취소」 버튼을 클릭할 수 있는 절차를 마련하여, 「동의」 버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section2" className={style.depth1}>2. 개인정보 처리 및 이용목적</div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스는 다음의 목적을 위하여 개인정보를 처리합니다. 처리한 개인정보는 다음 목적 이외의 용도로 사용되지 않으며, 이용 목적이 변경될 경우에는 사전 동의를 받습니다.
                </div>
                <div className={style.depth3}>
                    이용자들이 제공한 개인정보를 바탕으로 보다 더 유용한 서비스를 개발할 수 있습니다. 회사는 신규 서비스개발이나 컨텐츠의 확충시에 기존 이용자들이 (주)인트라밴 하이웨이서비스에 제공한 개인정보를 바탕으로 개발해야 할 서비 스의 우선 순위를 보다 더 효율적으로 정하고, 회사는 이용자들이 필요로 할 컨텐츠를 합리적으로 선택하여 제공할 수 있습니다.
                </div>
                <div className={style.depth3}>
                    각 수집정보별 수집목적은 다음과 같습니다.<br />
                    ① 회원 가입 및 관리: 회원 가입의사 확인, 회원제 서비스 제공, 본인 식별 및 인증, 서비스 부정 이용 방지, 각종 고지 및 통지.<br />
                    ② 서비스 제공 및 운영: 서비스 제공, 물품배송, 콘텐츠 제공, 맞춤서비스 제공, 본인 인증, 요금 결제 및 정산.<br />
                    ③ 고객 상담 및 민원 처리: 고객 불만 처리, 문의 응대 및 분쟁 해결.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section3" className={style.depth1}>
                    3. 개인정보 수집항목 및 수집방법
                </div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스는 서비스 제공 및 개선, 회원관리 등을 위하여 아래와 같은 개인정보를 수집하고 있습니다.
                </div>
                <div className={style.depth3}>
                    ▶ 필수항목<br />
                    - 계약자의 정보 - 생년월일, 이름, 전화번호, 휴대폰번호, 이메일, 주소<br />
                    - 납부자의 정보 - 생년월일, 이름, 전화번호, 휴대폰번호, 주소<br />
                    - 서비스관리자의 정보 - 아이디, 비밀번호, 이름, 전화, 이메일
                </div>
                <div className={style.depth3}>
                    ▶ 선택항목<br />
                    계약자의 팩스번호, 서비스관리자의 휴대폰번호, 납부자의 계좌번호와 신용카드 번호<br />
                    또한 서비스 이용과정이나 서비스 관련 업무 처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.<br />
                    - 서비스 접속시간, 접속ID, 접속한 IP주소정보, 결제기록<br />
                    - 고객이 직접 입력(제공)한 정보
                </div>
                <div className={style.depth3}>
                    ▶ 수집방법<br />
                    - 가입신청서 작성
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section4" className={style.depth1}>
                    4. 개인정보의 보유 및 이용기간
                </div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다. 정보주체의 법률적 의무가 남아 있는 경우에는 아래와 같이 보관합니다.
                </div>
                <div className={style.depth3}>
                    ▶ 사용료 미납 고객 정보<br />
                    상행위로 인한 채권·채무에 관한 청구권을 행사하기 위해 다음과 같은 기록을 보관합니다.<br />
                    가. 보관항목 : 성명, 생년월일, 전화번호(이동/일반), 요금내역(청구액, 부가세액, 수납액, 청구년월일, 수납년월일), 요금납부방법, 요금납부자명, 이용서 비스, 감액 금액 및 사유, 요금 청구지 주소, 프로그램 로그기록, 접속ID, 접속한 IP주소정보,<br />
                    나. 보관기간 : 5년
                </div>
                <div className={style.depth3}>
                    ▶ 전자상거래등에서의소비자보호에관한법률’에 근거한 보관기간<br />
                    가. 표시/광고에 관한 기록 : 6개월<br />
                    나. 계약 또는 청약철회 등에 관한 기록 : 5년<br />
                    다. 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br />
                    라. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
                </div>
                <div className={style.depth3}>
                    ▶ 신용정보의이용및보호에관한법률<br />
                    마. 신용정보의 수집/처리 및 이용 등에 관한 기록 : 5년
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section5" className={style.depth1}>
                    5. 개인정보의 파기 절차 및 방법
                </div>
                <div className={style.depth2}>
                    원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다.
                </div>
                <div className={style.depth3}>
                    ▶  파기절차<br />
                    - 서비스 가입 등을 위해 제공받은 정보는 수집 및 이용목적이 달성 된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유(보유 및 이용기간 참조)에 따라 파기됩니다.<br />
                    - 별도 DB로 옮겨진 개인정보는 법률에 의한 경우와 재가입시 가입비 면제 대상여부를 확 인하는 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.
                </div>
                <div className={style.depth3}>
                    ▶ 파기대상 : 보유기간 및 관련법령에 따른 보존기간이 종료된 고객정보
                </div>
                <div className={style.depth3}>
                    ▶ 파기방법<br />
                    - 종이(서면)에 작성·출력된 개인정보 : 분쇄하거나 소각 등의 방법으로 파기<br />
                    - DB 등 전자적 파일 형태로 저장된 개인정보 : 재생할 수 없는 기술적 방법으로 파기
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section6" className={style.depth1}>
                    6. 개인정보의 보호 및 관리
                </div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스는 다음과 같이 개인정보를 보호 및 관리하고 있습니다.
                </div>
                <div className={style.depth3}>
                    - 사용자 아이디와 비밀번호로 보호되고 있습니다.<br />
                    - 사용자 정보는 본인만이 직접 관리할 수 있습니다.<br />
                    - 정보 변경은 반드시 본인 확인 과정을 거쳐 정보를 편집할 수 있도록 시스템적으로 처리되어 있으므로 반드시 고객은 사용자 비밀번호를 직접 관리하셔야 합니다.<br />
                    - PC방이나 도서관 등과 같은 공공장소에서 컴퓨터를 이용하는 경우에는 특히 개인정보가 유출되지 않도록 유의하시기 바랍니다. 데이터 전송에 대한 보안성은 (주)인트라밴 하이웨이서비스에서 시스템적으로 최선을 다하여 보호하지만, 고객 여러분들도 개인정보 보호를 위하여 만전을 기하시기 바랍니다.<br />
                    - 고객의 개인정보에 대한 접근 및 관리에 필요한 절차 등을 마련하고 있으며 개인정보를 처리하는 시스템의 사용자를 지정하여 갱신 등의 관리를 하도록 하고 있으며 직무상 알게 된 개인정보를 타인에게 누설하거나 제공하지 않습니다.<br />
                    - 고객의 개인정보를 보호하기 위하여 관련 직원에게 필요한 교육을 시행하고 있고, 이행사항 및 관계자들의 준수 여부를 감사하기 위하여 적절한 절차를 마련하여 운영하고 있으며, 감사 결과 운영상의 시정 또는 개선을 요구하는 사항이나 위반내용이 있는 경우 이를 시정 또는 개선하고 기타 필요한 조치를 취하도록 최선을 다하고 있습니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section7" className={style.depth1}>
                    7. 이용자 및 법정대리인의 권리와 행사 방법
                </div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스는 다음과 같이 개인정보를 보호 및 관리하고 있습니다.<br />
                    이용자 및 법정대리인은 언제든지 개인정보에 대한 열람, 정정을 요구하시거나 가입해지 및 개인정보의 수집과 이용, 위탁 또는 제공에 대한 동의를 철회 할 수 있습니다.<br />
                    고객의 개인정보 열람 및 정정을 위해서는 '개인정보변경'(또는 '고객정보수정' 등)을, 가입해지(동의철회)를 위해서는 '해지신청서'를 작성하여 회사에 제출하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
                </div>
                <div className={style.depth3}>
                    ▶ 열람 또는 정정 요청 방법<br />
                    개인정보 보호와 권리보장을 위해 다음과 같은 열람 및 정정 절차를 제공해 드립니다.<br />
                    - 회사 직접 방문<br />
                    - 전화 상담<br />
                    - 이메일 문의
                </div>
                <div className={style.depth3}>
                    ▶ 본인 확인 절차<br />
                    열람 요청 시, 고객님의 신원 확인을 위해 다음 중 하나의 신분증 사본을 제시해 주시기 바랍니다.<br />
                    - 주민등록증<br />
                    - 여권<br />
                    - 운전면허증(신형)
                </div>
                <div className={style.depth3}>
                    ▶ 대리인이 요청 시 필요 서류<br />
                    - 위임장<br />
                    - 명의 고객의 인감증명서<br />
                    - 대리인의 신분증
                </div>
                <div className={style.depth3}>
                    ▶ 정보 정정 및 관리<br />
                    - 개인정보 오류 정정 요청 시, 해당 정보의 이용 및 제공을 즉시 중단하며, 정정 완료 후 재개됩니다. 이미 제3자에게 제공된 정보의 경우, 정정 사항을 신속히 통보하여 업데이트하도록 조치하겠습니다.
                </div>
                <div className={style.depth3}>
                    ▶ 동의 철회<br />
                    - 동의 철회를 요구하는 고객께서는 ‘본인확인절차’ 내지 ‘대리인확인절차’에 필요한 서류를 지참하여 회사 또는 지사를 방문하여 동의를 선택적으로 철회 할 수 있습니다.<br />
                    - 회사는 고객의 요청에 의해 해지 또는 삭제된 개인정보는 "개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 하고 있습니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section8" className={style.depth1}>
                    8. 만 14세미만 아동회원의 가입에 대한 법정대리인의 동의 관련 사항
                </div>
                <div className={style.depth2}>
                    만 14세 미만 아동회원의 가입을 받지 않습니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section9" className={style.depth1}>
                    9. 개인정보보호를 위한 노력(안전성 확보 조치)
                </div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.
                </div>
                <div className={style.depth3}>
                    ▶ 이용자들의 개인정보는 암호화하여 철저히 보호하고 있습니다.<br />
                    - 회원 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.<br />
                    - 따라서 이용자 여러분께서는 비밀번호를 누구에게도 알려주면 안됩니다.<br />
                    - PC에서의 사용을 마치신 후 온라인상에서 로그아웃(LOG-OUT)하시고 웹브라우저를 종료하도록 권장합니다. 특히 다른 사람과 PC를 공유하여 사용하거나 공공장소(회사나 학교, 도서관, 인터넷 게임방 등)에서 이용한 경우에는 개인정보가 다른 사람에게 알려지는 것을 막기 위해 위와 같은 절차가 더욱 필요할 것입니다.
                </div>
                <div className={style.depth3}>
                    ▶ 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.<br />
                    - 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하 여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호알고리즘 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.<br />
                    - 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 안정성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section10" className={style.depth1}>
                    10. 쿠키(cookie)의 운용에 관한 사항
                </div>
                <div className={style.depth2}>
                    회사는 홈페이지 운영에 있어 필요 시 고객의 정보를 찾아내고 저장하는 '쿠키(Cookie)'를 운용합니다. 쿠키는 회사의 웹사이트를 운영하는데 이용되는 서버가 고객의 브라우저에 보내는 아주 작은 텍스트 파일로서 고객의 컴퓨터 하드 디스크에 저장됩니다. 고객께서는 웹브라우저의 보안 정책을 통해 쿠키에 의한 정보수집의 허용 거부 여부를 결정하실 수 있습니다.
                </div>
                <div className={style.depth3}>
                    ▶ 쿠키에 의해 수집되는 정보<br />
                    - ID<br />
                    - 접속IP<br />
                    - 접속로그<br />
                    - 이용 컨텐츠 서비스 이용정보
                </div>
                <div className={style.depth3}>
                    ▶ 이용 목적<br />
                    - 고객의 관심분야에 따라 차별화된 정보를 제공
                </div>
                <div className={style.depth3}>
                    ▶ 쿠키 설치에 대한 선택권<br />
                    고객은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을 설정함으로써 쿠키에 의한 정보 수집 수준의 선택을 조정하실 수 있습니다.<br />
                    - 웹브라우저에서 쿠키에 의한 정보 수집 수준을 정할 수 있습니다.<br />
                    - 쿠키가 저장될 때마다 확인을 하거나, 아니면 모든 쿠키의 저장을 거부할 수 있습니다.<br />
                    단, 고객께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section11" className={style.depth1}>
                    11. 개인정보 관련 의견수렴 및 불만처리에 관한 사항
                </div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스는 개인정보보호와 관련하여 이용자 여러분들의 의견을 수렴하고 있으며 불만을 처리하기 위하여 절차와 방법을 마련하고 있습니다.
                </div>
                <div className={style.depth3}>
                    ▶ 회사 대표전화 및 이메일<br />
                    - 대표전화 : 1522-3840<br />
                    - 대표카페주소 : https://cafe.naver.com/blue3rlof<br />
                    - 이메일 : intravan@intravan.com<br />
                    전화나 카페, 이메일을 통하여 불만사항을 신고할 수 있고, (주)인트라밴 하이웨이서비스는 이용자들의 신고사항에 대하여 신속하고도 충분한 답변을 해 드릴 것입니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section12" className={style.depth1}>
                    12. 개인정보의 취급 위탁 및 제3자 제공
                </div>
                <div className={style.depth2}>
                    회사는 서비스의 제공을 위하여 이용자들의 개인정보를 외부전문업체에 위탁하여 처리할 수 있습니다. 개인정보의 취급 위탁은 개인정보취급방침에 명시한 범위내에서 이용하며, 개인정보의 취급 위탁 업체는 다음과 같습니다.
                </div>
                <div className={style.depth3}>
                    <table class={style.customTable}>
                        <thead>
                            <tr>
                                <th>수탁사</th>
                                <th>위탁목적</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>(주)오토데이터뱅크</td>
                                <td>
                                    관련상담업무, 제휴사에 대한 개인정보 동의 수취, 이벤트 정보 안내 등의 마케팅활동 및 고객관리, 고객DB 운용 및 유지보수, 상품안내용 문자 발송, 고객설문, 시장조사, 고지사항 전달, 이용계약 및 그부대업무의 대행, 이용 고객의 유지 및 관리
                                </td>
                            </tr>
                            <tr>
                                <td>효성에프엠에스(주)</td>
                                <td>효성CMS자동이체를 통한 요금 수납</td>
                            </tr>
                            <tr>
                                <td>주식회사 밴프로</td>
                                <td>CMS자동이체를 통한 요금 수납</td>
                            </tr>
                            <tr>
                                <td>삼정데이타서비스</td>
                                <td>전자세금계산서 발행 및 수취 서비스 유지관리</td>
                            </tr>
                            <tr>
                                <td>한국신용평가(주),한국신용정보(주), 미래신용정보(주)</td>
                                <td>체납관리, 실명인증 등</td>
                            </tr>
                            <tr>
                                <td>KAIMA, 카포스, 카컴, 새인천조합</td>
                                <td>국토부 정비이력전송 서비스 유지관리</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section13" className={style.depth1}>
                    13. 개인정보보호 책임자 및 연락처
                </div>
                <div className={style.depth2}>
                    회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만 및 문의사항을 처리하기 위하여 아 래와 같이 관련 부서를 지정하여 운영하고 있습니다. 또한 고객의 의견을 매우 소중하게 생각합니다. 고객께서 문의사항이 있을 경우 회사의 지사를 방문하시거나 콜센터 또는 개인정보담당부서로 문의하시면 신속.정확한 답변을 드리겠습니다.
                </div>
                <div className={style.depth3}>
                    ▶ 개인정보보호 책임자 및 연락처<br />
                    - 이름 : 곽용옥<br />
                    - 소속: 기업IT사업본부 스마트솔루션사업담당<br />
                    - 직위: 본부장<br />
                    - 전화 : 1522-3840<br />
                    - 메일 : intravan@intravan.com
                </div>
                <div className={style.depth3}>
                    ▶ 개인정보보호 책임자 역할<br />
                    - 개인정보보호 관련 주요 지침/정책/제도 수립 시행<br />
                    - 개인정보 유출, 오남용 방지를 위한 내부통제시스템 구축<br />
                    - 개인정보보호 분야 관리실태 감사 및 지도점검<br />
                    - 개인정보보호 교육 계획 수립 시행<br />
                    - 개인정보취급방침 수립 개정<br />
                    - 개인정보 침해사고 예방 대응<br />
                    - 기타 기타정보보호를 위하여 필요한 업무
                </div>
            </div>
            <div id="section14" className={style.wrapperDepth}>
                <div className={style.depth1}>
                    14. 개인정보침해 신고 및 상담 문의
                </div>
                <div className={style.depth2}>
                    개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다.
                </div>
                <div className={style.depth3}>
                    - 개인정보분쟁조정위원회 : www.kopico.go.kr / (1833-6972)<br />
                    - 개인정보침해신고센터 : privacy.kisa.or.kr / (118)<br />
                    - 대검찰청 : www.spo.go.kr / (1301)<br />
                    - 경찰청 : ecrm.police.go.kr / (182)
                </div>
            </div>
            <div id="section15" className={style.wrapperDepth}>
                <div className={style.depth1}>
                    15. 개인정보 처리방침의 변경에 관한 사항
                </div>
                <div className={style.depth2}>
                    본 개인정보처리방침은 2024. 12. 1부터 적용됩니다.<br />
                    이전의 개인정보처리방침은 상단 드롭다운 메뉴를 통해 확인하실 수 있습니다.
                </div>
            </div>
        </>
    )
}