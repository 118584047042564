import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Banner from "../comp/Banner";
import Header from "../comp/comm/Header";
import CompPrivacyPolicy from "./comp/CompPrivacyPolicy";
import CompTermsofUse from "./comp/CompTermsofUse";
import style from "./TermsOfService.module.css";

export default function TermsOfService() {
    const dispatch = useDispatch();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    useEffect(
        () => {
            dispatch({ type: "loadingChange", payload: false });
        },
        // eslint-disable-next-line
        []
    );

    return (
        <>
            <Header page="main" />

            <div className={style.wrapperDiv}>
                <div className={style.container}>
                    <ul className={style.wrapperTab}>
                        <li
                            className={selectedTabIndex === 0 ? style.selectedTab : null}
                            onClick={() => setSelectedTabIndex(0)}>개인정보 처리방침</li>
                        <li
                            className={selectedTabIndex === 1 ? style.selectedTab : null}
                            onClick={() => setSelectedTabIndex(1)}>이용약관</li>
                    </ul>
                    {
                        selectedTabIndex === 0
                            ? <CompPrivacyPolicy style={style} />
                            : <CompTermsofUse style={style} />
                    }
                </div>
            </div>
        </>
    );
}