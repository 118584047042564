// import { useEffect, useState } from "react";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
    // // scroll에 따라 top이동 버튼
    // useEffect(() => {
    //     window.addEventListener('scroll', getCurrentScroll)

    //     return () => window.removeEventListener('scroll', getCurrentScroll)
    // },
    // // eslint-disable-next-line
    // [])

    // const [scrollRevealCss, setScrollRevealCss] = useState('backtop js-scroll-trigger')
    // const getCurrentScroll = () => {
    //     if (window.scrollY > 300) {
    //         setScrollRevealCss('backtop js-scroll-trigger reveal')
    //     } else {
    //         setScrollRevealCss('backtop js-scroll-trigger')
    //     }
    // };

    // const fnMoveTop = () => {
    //     window.scrollTo(0, 0)
    // }

    const [pathName, setPathName] = useState("");

    useEffect(
        () => {
            setPathName(window.location.pathname);
        },
        // eslint-disable-next-line
        []
    );

    return (
        <>
            {pathName.toLowerCase().indexOf("vindecoder") === -1 &&
                pathName.toLowerCase().indexOf("est") === -1 ? (
                <div id="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-8 text-left footer-header-left mb-4">
                                <ul>
                                    <li>
                                        <span className="customer-tel">고객센터 1522-3840</span>
                                    </li>
                                    <li>
                                        <span>평일 09시~18시</span>
                                        <span>토/일요일,공휴일 휴무</span>
                                        <br />
                                        <span>
                                            <Link to="/terms-of-service">개인정보처리방침</Link>
                                        </span>
                                        <span>
                                            <Link to="/Intro">회사소개</Link>
                                        </span>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-4 footer-header-right channel mb-4">
                                <a
                                    href="https://www.youtube.com/channel/UCDzFFV5zWcuXAP1RK-LQ7UA"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src="./assets/y.png" alt="유튜브 채널" />
                                </a>
                                <a
                                    href="https://pf.kakao.com/_wLHdT"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src="./assets/k.png" alt="카카오 채널" />
                                </a>
                                <a
                                    href="https://blog.naver.com/kbk0437"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src="./assets/n.png" alt="네이버블로그" />
                                </a>
                                <a
                                    href="https://cafe.naver.com/blue3rlof"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src="./assets/c.png" alt="네이버카페" />
                                </a>
                                <a
                                    href="http://highway2000.co.kr"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src="./assets/h.png" alt="하이웨이 홈페이지" />
                                </a>
                            </div>
                        </div>

                        <hr style={{ borderColor: "#eeeeee" }} />

                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-2 text-left footer-header-left mt-4">
                                <img
                                    src="/assets/intravan-logo-footer.png"
                                    alt=""
                                    className="img-fluid img-logo"
                                />
                            </div>

                            <div className="col-sm-12 col-md-12 col-lg-10 footer-header-right mt-4 footer-contents">
                                <p>
                                    [우 12925] 경기도 하남시 미사대로 520, 현대지식산업센터
                                    한강미사 2차 C동 713호
                                </p>
                                <p>
                                    대표전화 : 1522-3840&nbsp;&nbsp;&nbsp;
                                    <span>팩스번호 : 031-8018-4765</span>{" "}
                                </p>
                                <p>
                                    대표이사 : 이건택&nbsp;&nbsp;&nbsp;사업자등록번호 :
                                    215-81-90952
                                </p>
                                <p>
                                    INTRAVAN Co.,Ltd. The specialized software for automobile
                                    administration. Copyright@2022 by INTRAVAN. ALL Rights
                                    reserved.
                                </p>
                            </div>
                        </div>
                        {/* 
                <div className="row">
                    <div className="col-lg-4">
                        <span className={ scrollRevealCss } onClick={ fnMoveTop }>
                            <i className="fas fa-arrow-up" />
                        </span>
                    </div>
                </div> */}
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default Footer;
