export default function CompTermsofUse({ style }) {
    return (
        <>
            <div className={style.wrapperDepth}>
                <div className={style.depth1}>
                    제 1 조 목 적
                </div>
                <div className={style.depth2}>
                    이 약관은 (주)인트라밴이“자동차정비솔루션 및 제공서비스”(이하 “하이웨이”라 한다) 이용과 관련하여 제반사항에 대한 규정함을 목적으로 한다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div className={style.depth1}>
                    제 2 조 하이웨이 제공서비스 내용
                </div>
                <div className={style.depth2}>
                    본 서비스 공급자가 이용자에게 제공하는 하이웨이서비스 내용은 아래와 같다.
                </div>
                <div className={style.depth2}>
                    1) 하이웨이서비스 가입신청을 한 경우 법정 근무일 기준 5일 이내에 신청서 작성 및 설치, 솔루션 등의 서비스를 이용 할 수 있도록 한다.
                </div>
                <div className={style.depth2}>
                    2) 인터넷을 통한 프로그램 솔루션 기능개선사항을 수시로 제공하며 사용 중 발생한 장애 문제의 상담 및 조치 등의 서비스(이하 "A/S"라 한다)를 제 3조의 기준에 의거 제공한다. 단, A/S 대상은 서비스 가입신청서 기준에 준하며 자동차정비솔루션 및 탑재 데이터로 범위를 한정한다.
                </div>
                <div className={style.depth2}>
                    3) 하이웨이서비스의 기능향상을 위한 업그레이드 및 A/S, 이용 교육에 대한 서비스를 인터넷 등을 이용하여 원격으로 신속하게 지원한다.
                </div>
                <div className={style.depth2}>
                    4) 서비스 가입자에 한하여 온라인 고객서비스 관리도구(SMS,LMS, APP 등)가 솔루션에서 제공되며, 개통 후 즉시 사용이 가능하다.또한, 사용한 내용을 솔루션에서 검색할 수 있으며 각 통신사 이용요금에 준한다.
                </div>
                <div className={style.depth2}>
                    5) 서비스 설치 및 교육은 1회 방문 또는 원격 설치, 교육을 원칙으로 하며 재방문 설치, 교육 요청시 별도의 출장비를 청구할 수 있다.
                </div>
                <div className={style.depth2}>
                    6) 자동차정비솔루션으로 업로드 한 사진파일은 1년 보관 및 12GB 스토리지 사용량을 제공하고, 스토리지 사용량 초과시 이용량에 따라 사용자에게 별도의 추가요금이 발생할 수 있다.
                </div>
                <div className={style.depth2}>
                    7) 사용자의 정비매출 데이터는 최근 10년간 데이터를 제공하고, 10년 이상 데이터는 별도의 서비스를 통해 제공하도록 한다
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div className={style.depth1}>
                    제 3 조 A/S 제공 기준
                </div>
                <div className={style.depth2}>
                    1) 고객안내센터(Help Desk)를 운영하여 사용자의 문의에 응답하며, 원격제어시스템을 통해 장애해소 서비스를 제공한다. : 서비스 제공시간 (평일 : 09:00-18:00 / 토요일, 일요일, 공휴일, 국경일 : 휴무), 업무시간 외는 웹사이트(intravan.com)에서 A/S 요청을 받아 다음날 업무개시 직후 순차적으로 조치한다.
                </div>
                <div className={style.depth2}>
                    2) 고객안내센터에서 조치가 어려운 사안에 대해 방문서비스를 제공한다. 단, 하이웨이서비스와 관련 없는 사안(PC, 프린터, 인터넷, 바이러스, 화재, 도난, 천재지변, 다른 프로그램 연관, 사용자의 교체 및 사용미숙으로 인한 방문교육요청 등)의 경우는 별도의 출장비를 청구할 수 있다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div className={style.depth1}>
                    제 4 조 서비스 이용요금 및 요금청구, 납부방법
                </div>
                <div className={style.depth2}>
                    1) 서비스 이용요금은 각각의 제공서비스(기본서비스, 부가서비스, SMS등) 이용요금에 준하여 ㈜인트라밴이 청구한다.
                </div>
                <div className={style.depth2}>
                    2) 이용료는 월단위로 후불제를 적용하며, 서비스 개통일을 기준으로 일할로 계산하여 과금청구한다.
                </div>
                <div className={style.depth2}>
                    3) 이용요금청구는 자동이체 등으로 하며 청구서를 세금계산서로 대체한다.
                </div>
                <div className={style.depth2}>
                    4) 신청인과 납부자가 상이한 경우 반드시 신청인의 서명을 필하고 납부자의 고의 및 과실 등으로 인한 체납발생피해는 신청인에게 귀책된다.
                </div>
                <div className={style.depth2}>
                    5) 인트라밴은 물가상승률, 서버 및 고객센터 운영비증감, 사용자편의를 위한 신규 서비스 구축에 따른 비용 증감율에 따라 사용료를 증감할 수 있으며 매년 01월 공지를 통해 안내 한다
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div className={style.depth1}>
                    제 5 조 서비스 사용 해지(취소) 또는 중지
                </div>
                <div className={style.depth2}>
                    1) 계약자는㈜인트라밴(02-424-1901)를 통해 서비스 사용 해지(취소) 요청을 할 수 있고, 반드시 유,무선통신을 이용해 계약자가 직접 해지신청을 하여야 한다.서비스 이용요금은 해지신청일까지 일할 계산하여 익월 납부하여야 하고 해지신청을 하지 않을 시 사용여부와 관계없이 사용 중으로 간주. 이용요금이 계속 부과된다.
                </div>
                <div className={style.depth2}>
                    2) 서비스 사용 해지(취소)자는 프로그램 및 각종 제공서비스를 이용할 수 없으며, 데이터 이전 요청 시 서비스 제공자는 파일로 수리이력 및 고객정보만을 제공한다.
                </div>
                <div className={style.depth2}>
                    3) 요금 체납시㈜인트라밴은 서비스 이용을 정지 할 수 있고, 요금납부사항이 확인되면 정지를 해제할 수도 있다. 사용자가 서비스 이용요금을 2회 체납한 경우, 자동으로 정지되며 별도의 사전통지 없이 서비스 이용을 중지 또는 중지할 것을 통보할 수 있다.
                </div>
                <div className={style.depth2}>
                    4) 요금체납이 있는 이용자(미납자)가 체납요금을 납부할 경우, 제공자가 요금납부사실을 확인한 익일부터 서비스 이용을 재개한다. 단 이 경우는 동 서비스 이용신청이 유효한 것으로 본다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div className={style.depth1}>
                    제 6 조 서비스 제공자 면책사항
                </div>
                <div className={style.depth2}>
                    1) 사용자 또는 사용자의 지시를 받은 자가 프로그램을 임의로 조작함으로써 프로그램에 오류가 발생한 피해
                </div>
                <div className={style.depth2}>
                    2) 계약자가 서비스 사용 해지신청 및 명의 변경 신청을 하지 않아 발생하는 피해
                </div>
                <div className={style.depth2}>
                    3) 화재, 도난, 천재지변 등의 사유로 인한 피해
                </div>
                <div className={style.depth2}>
                    4) 데이터의 관리미숙 또는 관리부실로 인한 손상 및 바이러스, 악성코드로 인한 피해
                </div>
                <div className={style.depth2}>
                    5) 제 3자로 인하여 제공서비스 또는 다른 프로그램을 A/S하는 과정에서 발생한 피해
                </div>
                <div className={style.depth2}>
                    6) 데이터3법에 의거 차주의 요청에 의해 제공되는 정보로 발생하는 피해
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div className={style.depth1}>
                    제 7 조 데이터3법 관련 사항
                </div>
                <div className={style.depth2}>
                    1) 데이터3법 시행에 따라 차량의 각종정보 및 수리이력 등을 관련법규에 의거 차주가 지정한 자에게 제공한다.
                </div>
                <div className={style.depth2}>
                    2) 차량의 수리이력 등의 정보제공은 인트라밴, 수탁사, 제휴사를 통하여 제공될 수 있다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div className={style.depth1}>
                    제 8 조 일반 사항
                </div>
                <div className={style.depth2}>
                    1) 계약 당사자는 사전 동의 없이 본 계약의 어떠한 권리나 의무를 제3자에게 양도, 수탁, 저당, 이전, 판매할 수 없다.
                </div>
                <div className={style.depth2}>
                    2) 이용약관에 명시되지 않은 사항은 일반 상관례를 따르며, 분쟁에 대하여는 서울중앙지방법원을 관할법원으로 지정한다.
                </div>
            </div>
        </>
    );
}