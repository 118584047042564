import Banner from "./Banner";
import VerticalCarousel from "./VerticalCarousel";
import JumboCount from "./JumboCount";
import HighwayTv from "./HighwayTv";
import Partner from "./Partner";
import Direction from "./Direction";
import Header from "../comp/comm/Header";
import ModalPopup from "./comm/modalPopup";
import WidgetProgramChooseHelper from "../widget/WidgetProgramChooseHelper";
import VisibilitySensor from "react-visibility-sensor";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reqData } from "../js/server";

function MainContents() {
    const [countNum, setCountNum] = useState([]);
    const [boxAnimation01, setBoxAnimation01] = useState(null);
    const [boxAnimation02, setBoxAnimation02] = useState(null);
    const [luseCount, setLuseCount] = useState(null);
    const SINCE_YEAR = 1991;
    const [showModal, setShowModal] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);

    const getNowYear = () => {
        var now = new Date();
        var year = now.getFullYear();

        return year;
    }

    const onVisibilityChange = (isVisible) => {
        if (isVisible) {
            const NOW_YEAR = getNowYear();
            setCountNum([SINCE_YEAR, luseCount, NOW_YEAR - SINCE_YEAR]);
        }
    };

    const onVisibilityBox01Change = (isVisible) => {
        if (isVisible) {
            setBoxAnimation01("1");
        }
    };

    const onVisibilityBox02Change = (isVisible) => {
        if (isVisible) {
            setBoxAnimation02("1");
        }
    };

    const dispatch = useDispatch();

    const getLuseCount = () => {
        const jsonData = {
            servicename: "LUSE_COUNT",
        };

        dispatch({ type: "loadingChange", payload: true });
        reqData(jsonData)
            .then((res) => {
                if (res.data.result === "OK") {
                    setLuseCount(res.data.luseCount);
                } else {
                    alert(res.data.msg);
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setTimeout(() => {
                    dispatch({ type: "loadingChange", payload: false });
                }, 500);
            });
    };

    const handleCloseModal = () => {
        setShowInfoModal(false);
    };

    const handleHideForToday = () => {
        setShowInfoModal(false);

        localStorage.setItem("hideForToday", new Date().getDate());
    };

    useEffect(
        () => {
            dispatch({ type: "loadingChange", payload: true });

            getLuseCount();

            // 오토X 팝업 Disabled
            // const hideForToday = localStorage.getItem("hideForToday");

            // if (!hideForToday || new Date().getDate() !== Number(hideForToday)) {
            //     setShowInfoModal(true);
            // }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <>
            <Header page="main" />

            <Banner />

            <div className="container pt-7 pb-7 animated animatedFadeInUp fadeInUp">
                <div className="row pt-5">
                    <div className="col-sm-12 col-md-10 col-lg-10">
                        <span className="sub-title ">제품소개</span>
                        <span className="sub-title-descr1">업계 1위</span>
                        <span className="sub-title-descr2">
                            의 자부심! 사업장에 맞는 솔루션을 소개합니다.
                        </span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <VerticalCarousel setShowModal={setShowModal} />
                    </div>
                </div>
            </div>

            <JumboCount onVisibilityChange={onVisibilityChange} countNum={countNum} />

            {/* 영상교육 */}

            <div className={`container pt-7 opacity-0 ${boxAnimation01 === "1" ? "animated animatedFadeInUp fadeInRight" : ""}`} >
                <div className="row mt-5">
                    <div className="col-sm-12 col-md-10 col-lg-10">
                        <span className="sub-title">체험하기</span>
                        <span className="sub-title-descr1">하이웨이 TV</span>
                        <span className="sub-title-descr2">
                            에서 인트라밴의 모든 솔루션을 경험해 보세요!
                        </span>
                    </div>

                    <div className="col-md-2 col-lg-2 mt-3 text-right main-more">
                        <a
                            href="https://www.youtube.com/channel/UCDzFFV5zWcuXAP1RK-LQ7UA"
                            target="_blank"
                            rel="noreferrer"
                        >
                            more
                            <img src="./assets/icon-right-arrow.png" alt="right-arrow" />
                        </a>
                    </div>
                </div>

                <VisibilitySensor
                    scrollCheck={true}
                    onChange={onVisibilityBox01Change}
                    delayedCall
                >
                    <div className="row">
                        <div className="col-12">
                            <HighwayTv />
                        </div>
                    </div>
                </VisibilitySensor>
            </div>

            {/* 모아보기 */}

            <div className={`container pt-9 opacity-0 ${boxAnimation02 === "1" ? "animated animatedFadeInUp fadeInLeft" : ""}`}>
                <div className="row mt-5">
                    <div className="col-12">
                        <span className="sub-title">모아보기</span>
                        <span className="sub-title-descr1">인트라밴</span>
                        <span className="sub-title-descr2">
                            의 다양한 활동을 확인하세요!
                        </span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 text-center m-0 p-0 pt-2">
                        <VisibilitySensor
                            scrollCheck={true}
                            onChange={onVisibilityBox02Change}
                            delayedCall
                        >
                            <div className="main-btn">
                                <Link to="/Intro">
                                    <img
                                        src="./assets/main_btn01.png"
                                        alt="회사소개"
                                        onMouseOver={(e) =>
                                            (e.currentTarget.src = "./assets/main_btn01_o.png")
                                        }
                                        onMouseOut={(e) =>
                                            (e.currentTarget.src = "./assets/main_btn01.png")
                                        }
                                    />
                                </Link>
                            </div>
                        </VisibilitySensor>

                        <div className="main-btn">
                            <Link to="/History">
                                <img
                                    src="./assets/main_btn02.png"
                                    alt="회사연혁"
                                    onMouseOver={(e) =>
                                        (e.currentTarget.src = "./assets/main_btn02_o.png")
                                    }
                                    onMouseOut={(e) =>
                                        (e.currentTarget.src = "./assets/main_btn02.png")
                                    }
                                />
                            </Link>
                        </div>

                        <div className="main-btn">
                            <Link to="/Question">
                                <img
                                    src="./assets/main_btn03.png"
                                    alt="제품문의"
                                    onMouseOver={(e) =>
                                        (e.currentTarget.src = "./assets/main_btn03_o.png")
                                    }
                                    onMouseOut={(e) =>
                                        (e.currentTarget.src = "./assets/main_btn03.png")
                                    }
                                />
                            </Link>
                        </div>

                        <div className="main-btn">
                            <a
                                href="https://www.youtube.com/channel/UCDzFFV5zWcuXAP1RK-LQ7UA"
                                target="new"
                            >
                                <img
                                    src="./assets/main_btn04.png"
                                    alt="하이웨이TV"
                                    onMouseOver={(e) =>
                                        (e.currentTarget.src = "./assets/main_btn04_o.png")
                                    }
                                    onMouseOut={(e) =>
                                        (e.currentTarget.src = "./assets/main_btn04.png")
                                    }
                                />
                            </a>
                        </div>

                        <div className="main-btn">
                            <a href="https://pf.kakao.com/_wLHdT" target="new">
                                <img
                                    src="./assets/main_btn05.png"
                                    alt="카카오톡채널"
                                    onMouseOver={(e) =>
                                        (e.currentTarget.src = "./assets/main_btn05_o.png")
                                    }
                                    onMouseOut={(e) =>
                                        (e.currentTarget.src = "./assets/main_btn05.png")
                                    }
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container pt-9">
                <div className="row mt-5">
                    <div className="col-12 text-center">
                        <p className="label-partner-ti-01">인트라밴 제휴사</p>
                        <p className="label-partner-ti-02">
                            많은 기업들이 인트라밴과 함께하고 있습니다.
                        </p>
                    </div>
                </div>

                {/* 파트너사 */}
                <Partner />
            </div>

            {/* 오시는길 */}
            <Direction />

            {/* ModalPopup */}
            {showInfoModal && (
                <ModalPopup
                    handleCloseModal={handleCloseModal}
                    handleHideForToday={handleHideForToday}
                />
            )}

            {showModal &&
                <WidgetProgramChooseHelper
                    handleCloseModal={handleCloseModal}
                    showModal={showModal}
                />
            }
        </>
    );
}

export default MainContents;
