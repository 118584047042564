import { handleScroll } from "../../js/util";

export default function CompPrivacyPolicy241216({ style }) {
    return (
        <>
            <ul className={style.wrapperIndex}>
                <li onClick={() => handleScroll("section1")}>가. 개인정보 수집에 대한 동의</li>
                <li onClick={() => handleScroll("section2")}>나. 개인정보의 처리 및 이용목적</li>
                <li onClick={() => handleScroll("section3")}>다. (주)인트라밴 하이웨이서비스가 수집하는 개인정보 항목 및 수집방법</li>
                <li onClick={() => handleScroll("section4")}>라. (주)인트라밴 하이웨이서비스가 수집하는 개인정보의 보유 및 이용기간</li>
                <li onClick={() => handleScroll("section5")}>마. 개인정보의 파기절차 및 방법</li>
                <li onClick={() => handleScroll("section6")}>바. (주)인트라밴 하이웨이서비스가 수집한 개인정보의 보호 및 관리</li>
                <li onClick={() => handleScroll("section7")}>사. 이용자및 법정 대리인의 권리와 그 행사방법</li>
                <li onClick={() => handleScroll("section8")}>아. 만 14세미만 아동회원의 가입에 대한 법정대리인의 동의 관련 사항</li>
                <li onClick={() => handleScroll("section9")}>자. 쿠키(cookie)의 운영에 관한 사항</li>
                <li onClick={() => handleScroll("section10")}>차. 개인정보관련 기술적-관리적 대책</li>
                <li onClick={() => handleScroll("section11")}>카. 개인정보관련 의견수렴 및 불만처리에 관한 사항</li>
                <li onClick={() => handleScroll("section12")}>타. 개인정보의 취급 위탁</li>
                <li onClick={() => handleScroll("section13")}>파. 제 3자에 대한 제공</li>
                <li onClick={() => handleScroll("section14")}>하. 개인정보 관리책임 부서 및 연락처</li>
            </ul>
            <div className={style.wrapperDepth}>
                <div id="section1" className={style.depth1}>
                    가. 개인정보 수집에 대한 동의
                </div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스는 이용자들이 (주)인트라밴 하이웨이서비스의 개인정보취급방침 또는 이용약관의 내용 에 대하여 「동의」버튼 또는 「취소」버튼을 클릭할 수 있는 절차를 마련하여, 「동의」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section2" className={style.depth1}>
                    나. 개인정보의 수집목적 및 이용목적
                </div>
                <div className={style.depth2}>
                    "개인정보"라 함은 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다. (주)인트라밴 하이웨이서비스는 이용자의 사전 동의 없이는 이용자의 개인 정보를 함부로 공개하지 않으며, 수집 된 정보는 아래와 같이 이용하고 있습니다.
                </div>
                <div className={style.depth3}>
                    첫째, 이용자들이 제공한 개인정보를 바탕으로 보다 더 유용한 서비스를 개발할 수 있습니다. 회사는 신규 서비스개발이나 컨텐츠의 확충시에 기존 이용자들이 (주)인트라밴 하이웨이서비스에 제공한 개인정보를 바탕으로 개발해야 할 서비 스의 우선 순위를 보다 더 효율적으로 정하고, 회사는 이용자들이 필요로 할 컨텐츠를 합리적으로 선택하여 제공할 수 있습니다.
                </div>
                <div className={style.depth3}>
                    둘째, 각 수집정보별 수집목적은 다음과 같습니다.<br />
                    1. 성명, 주민등록번호, 아이디, 비밀번호 : 회원가입시 본인식별<br />
                    2. 이메일주소, 주소, 전화번호 : 고지사항 전달, 본인의사확인, 불만처리 등 원활한 의사소통 경로의 확보, 새로운 서비스나 신상품,이벤트 정보 등 최신정보의 안내<br />
                    3. 그 외 항목 : 개인맞춤서비스를 제공하기 위한 자료
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section3" className={style.depth1}>
                    다. (주)인트라밴 하이웨이서비스가 수집하는 개인정보 항목 및 수집방법
                </div>
                <div className={style.depth2}>
                    1. 하이웨이 서비스는 서비스 제공 및 개선, 회원관리 등을 위하여 아래와 같은 개인정보를 수집하고 있습니다.
                </div>
                <div className={style.depth3}>
                    ㅇ 필수항목 :
                    계약자의 정보<br />
                    - 주민등록번호(가입신청서를 서면으로 작성시 제공), 이름, 전화번호, 휴대 폰번호, 이메일, 주소 납부자의 정보 <br />
                    - 주민등록번호(가입신청서를 서면으로 작성시 제공), 이름, 전화번호, 휴대 폰번호, 주소 서비스관리자의 정보<br />
                    - 아이디, 비밀번호, 이름, 전화, 이메일
                </div>
                <div className={style.depth3}>
                    ㅇ 선택항목 :<br />
                    계약자의 팩스번호, 서비스관리자의 휴대폰번호, 납부자의 계좌번호와 신용카드 번호<br />
                    또한 서비스 이용과정이나 서비스 관련 업무 처리 과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.
                </div>
                <div className={style.depth3}>
                    ㅇ 서비스 접속시간, 접속ID, 접속한 IP주소정보, 결제기록
                </div>
                <div className={style.depth3}>
                    ㅇ 고객이 직접 입력(제공)한 정보
                </div>
                <div className={style.depth3}>
                    ▶ 수집방법<br />
                    - 가입신청서 작성
                </div>
                <div className={style.depth2}>
                    2. 하이웨이 서비스는 다음과 같은 방법으로 개인정보를 수집합니다.
                </div>
                <div className={style.depth3}>
                    ㅇ 가입신청서 작성
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section4" className={style.depth1}>
                    라. (주)인트라밴 하이웨이서비스가 수집하는 개인정보의 보유 및 이용기간
                </div>
                <div className={style.depth2}>
                    하이웨이 서비스는 개인정보의 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파 기합니다. 단, 미납 또는 체납이 발생한 경우 해당 문제가 해결될 때까지 관렦정보를 보존하며 문제 해결 시 파기합니다. 그 러나 관련 법령에 의거하여 KT의 고객정보 관련 시스템에 아래와 같이 보관합니다.
                </div>
                <div className={style.depth3}>
                    1.해지고객의 개인정보는 국세기본법 제85조의3 규정에 따라 다음 항목의 정보를 보관합니다.<br />
                    가. 보관항목 : 성명, 고객식별번호(주민등록번호/여권번호/외국인등록번호 등), 전화번호(이동/일반), 요금내역(청구액, 부가세액, 수납액, 청구년월일, 수납년월일), 요금납부방법, 요금납부자명, 이용서비스, 감액 금액 및 사유, 요금 청구지 주소 등<br />
                    나. 보관기간 : 5년
                </div>
                <div className={style.depth3}>
                    2. 통신비밀보호법에 따른 전기통신사실에 관한 자료는 다음의 기준에 따라 보관합니다.<br />
                    가. 통신사실확인자료 제공에 필요한 성명, 주민등록번호, 이동전화번호 : 12개월<br />
                    나. 가입자의 전기통신일시, 전기통신개시ㆍ종료시간, 발ㆍ착신 통신번호 등 상대방의 가입자번호, 사용도수, 발신기지국의 위치정보 : 12개월(단, 시외/시내전화 역무와 관련된 자료인 경우에는 6개월 보관)<br />
                    다. 컴퓨터통신 또는 인터넷의 로그기록자료, 정보통신기기의 위치를 확인할 수 있는 접속지의 추적자료 : 3개월
                </div>
                <div className={style.depth3}>
                    3. 기타<br />
                    가. 표시/광고에 관핚 기록:6개월(전자상거래등에서의 소비자보호에 관한 법률)<br />
                    나. 계약 또는 청약철회 등에 관한 기록:5년(전자상거래등에서의 소비자보호에 관한 법률)<br />
                    다. 대금결제 및 재화 등의 공급에 관핚 기록:5년(전자상거래등에서의 소비자보호에 관한 법률)<br />
                    라. 소비자의 불만 또는 분쟁처리에 관한 기록:3년(전자상거래등에서의 소비자보호에 관한 법률)<br />
                    마. 신용정보의 수집/처리 및 이용 등에 관한 기록:3년(신용정보의 이용 및 보호에 관한 법률)
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section5" className={style.depth1}>
                    마. 개인정보의 파기절차 및 방법
                </div>
                <div className={style.depth2}>
                    ㅇ 개인정보의 파기절차 및 방법<br />
                    회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파 기합니다. 파기절차 및 방법은 다음과 같습니다.
                </div>
                <div className={style.depth3}>
                    1. 파기절차<br />
                    가. 원칙적으로 고객께서 서비스 가입 등을 위해 제공하신 정보는 수집 및 이용목적이 달성 된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유(보유 및 이용기간 참조)에 따라 일정기 간 저장된 후 파기됩니다. <br />
                    나. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우와 재가입시 가입비 면제 대상여부를 확 인하는 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.<br />
                    다. 파기대상 : 보유기간 및 관련법령에 따른 보존기간이 종료된 고객정보
                </div>
                <div className={style.depth3}>
                    2. 파기방법<br />
                    가. 종이(서면)에 작성·출력된 개인정보 : 분쇄하거나 소각 등의 방법으로 파기<br />
                    나. DB 등 전자적 파일 형태로 저장된 개인정보 : 재생할 수 없는 기술적 방법으로 삭제
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section6" className={style.depth1}>
                    바. (주)인트라밴 하이웨이서비스가 수집한 개인정보의 보호 및 관리
                </div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스개인정보는 사용자 아이디와 비밀번호로 보호되고 있습니다. 사용자 정보는 본인만이 직접 관리할 수 있습니다.<br />
                    정보 변경은 반드시 본인 확인 과정을 거쳐 정보를 편집할 수 있도록 시스템적으로 처리되어 있으므로 반드 시 고객은 사용자 비밀번호를 직접 관리하셔야 합니다.<br />
                    PC방이나 도서관 등과 같은 공공장소에서 컴퓨터를 이용하는 경우에는 특히 개인정보가 유출되지 않도록 유 의하시기 바랍니다.<br />
                    단, 인터넷을 통한 데이터 전송에 대한 보안성은 (주)인트라밴 하이웨이서비스에서 시스템적으로 최선을 다하여 보호하지만, 고객 여러분들도 개인정보 보호를 위하여 만전을 기하시기 바랍니다.<br />
                    (주)인트라밴 하이웨이서비스는 고객의 개인정보에 대한 접근 및 관리에 필요한 절차 등을 마련하고 있으며 개인 정보를 처리하는 시스템의 사용자를 지정하여 갱신 등의 관리를 하도록 하고 있으며 직무상 알게 된 개인정보를 타인에게 누 설하거나 제공하지 않습니다.<br />
                    또한 고객의 개인정보를 보호하기 위하여 관련 직원에게 필요한 교육을 실시하고 있고, 본 정책에 명시된 이 행사항 및 관련 직원의 준수여부를 감사하기 위하여 적절한 절차를 마련하여 운영하고 있으며 감사결과 운영상의 시정 또는 개선을 요구하는 사항이나 위반내용이 있는 경우 이를 시정 또는 개선하고 기타 필요한 조치를 취하도록 최선을 다하고 있습니다.<br />
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section7" className={style.depth1}>
                    사. 이용자 및 법정 대리인의 권리와 그 행사방법
                </div>
                <div className={style.depth2}>
                    이용자 및 법정 대리인은 언제든지 개인정보에 대한 열람, 정정을 요구하시거나 가입해지 및 개인정보의 수 집과 이용, 위탁 또는 제공에 대한 동의를 철회를 하실 수 있습니다. 고객의 개인정보 열람 및 정정을 위해서는 '개인정보변경'(또는 '고 객정보수정' 등)을, 가입해지(동의철회)를 위해서는 '해지신청서'를 작성하여 회사에 제출하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.
                </div>
                <div className={style.depth3}>
                    1. 개인정보의 열람증명 또는 정정<br />
                    가. 고객께서는 회사를 직접 방문하시거나 전화, 이메일 등을 통하여 개인정보에 대한 열람증명을 요구할 수 있습니다.<br />
                    나. 고객께서 본인의 개인정보에 대한 열람증명을 요구하시는 경우 고객의 신분을 증명할 수 있는 주민등록증, 여권, 운전면 허증(신형) 등의 신분증명(사본)을 제시 받아 본인 여부를 확인합니다.<br />
                    다. 고객의 대리인이 열람증명을 요구하는 경우에는 대리 관계를 나타내는 위임장, 명의고객의 인감증명서와 대리인의 신분증 명서 등의 증표를 제시 받아 대리인인지 여부를 확인합니다.<br />
                    라. 고객의 개인정보는 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.
                </div>
                <div className={style.depth3}>
                    2. 개인정보의 수집과 이용 또는 제공에 대한 동의 철회<br />
                    가. 고객께서는 위 제1항 '나'에 고지된 신분증을 지참하시고 회사의 지사를 방문하여 개인정보의 수집과 이용, 위탁 또는 제 공에 대해 동의하시거나, 이에 대한 동의를 선택적으로 철회하실 수 있습니다.<br />
                    나. 회사는 고객의 요청에 의해 해지 또는 삭제된 개인정보는 "회사가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section8" className={style.depth1}>
                    아. 만 14세미만 아동회원의 가입에 대한 법정대리인의 동의 관련 사항
                </div>
                <div className={style.depth2}>
                    만 14세 미만 아동회원의 가입을 받지 않습니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section9" className={style.depth1}>
                    자. 쿠키(cookie)의 운영에 관한 사항
                </div>
                <div className={style.depth2}>
                    회사는 홈페이지 운영에 있어 필요 시 고객의 정보를 찾아내고 저장하는 '쿠키(Cookie)'를 운용합니다. 쿠키 는 회사의 웹사이트를 운영하는데 이용되는 서버가 고객의 브라우저에 보내는 아주 작은 텍스트 파일로서 고객의 컴퓨터 하드 디스크에 저장됩니다. 고객께서는 웹브라우저의 보안 정책을 통해 쿠키에 의한 정보수집의 허용 거부 여부를 결정하실 수 있 습니다.
                </div>
                <div className={style.depth3}>
                    1. 쿠키에 의해 수집되는 정보 및 이용 목적<br />
                    가. 수집 정보 : ID, 접속IP, 접속로그, 이용 컨텐츠 등 서비스 이용정보<br />
                    나. 이용목적<br />
                    ㅇ 고객의 관심분야에 따라 차별화된 정보를 제공
                </div>
                <div className={style.depth3}>
                    2. 고객은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 웹브라우저에서 옵션을 설 정함으로써 쿠키에 의한 정보 수집 수준의 선택을 조정하실 수 있습니다.<br />
                    가. 웹브라우저의 [도구]메뉴에서 [인터넷옵션]->[보안]->[사용자 정의 수준]을 선택하여 쿠키에 의한 정보 수집 수준을 정할 수 있습니다.<br />
                    나. 위에 제시된 메뉴를 통해 쿠키가 저장될 때마다 확인을 하거나, 아니면 모든 쿠키의 저 장을 거부할 수도 있습니다. 단, 고객께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section10" className={style.depth1}>
                    차. 개인정보관련 기술적-관리적 대책
                </div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼 손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.
                </div>
                <div className={style.depth3}>
                    이용자들의 개인정보는 비밀번호에 의해 철저히 보호되고 있습니다.<br />
                    (주)인트라밴 하이웨이서비스 회원 아이디(ID)의 비밀번호는 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비 밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서 이용자 여러분께서는 비밀번호를 누구에게도 알려주시면 안됩니다. 이를 위해 (주)인트라밴 하이웨이서비스에서는 기본적으로 PC에서의 사용을 마치신 후 온라인상에서 로그아웃(LOG-OUT)하시고 웹브 라우저를 종료하도록 권장합니다. 특히 다른 사람과 PC를 공유하여 사용하거나 공공장소(회사나 학교, 도서관, 인터넷 게임방 등)에서 이용한 경우에는 개인정보가 다른 사람에게 알려지는 것을 막기 위해 위와 같은 절차가 더욱 필요할 것입니다.
                </div>
                <div className={style.depth3}>
                    (주)인트라밴 하이웨이서비스는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하 여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호알고리즘 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하 고 있으며, 기타 시스템적으로 안정성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section11" className={style.depth1}>
                    카. 개인정보관련 의견수렴 및 불만처리에 관한 사항
                </div>
                <div className={style.depth2}>
                    (주)인트라밴 하이웨이서비스는 개인정보보호와 관련하여 이용자 여러분들의 의견을 수렴하고 있으며 불만을 처리하 기 위하여 모든 절차와 방법을 마련하고 있습니다. 이용자들은 하단에 명시한 "차. (주)인트라밴 하이웨이서비스 개인정보 관리책 임자 및 담당자의 소속-성명 및 연락처"항을 참고하여 전화나 메일을 통하여 불만사항을 신고할 수 있고, (주)인트라밴 하이웨이서 비스는 이용자들의 신고사항에 대하여 신속하고도 충분한 답변을 해 드릴 것입니다. 또는 정부에서 설치하여 운영중인 개인정 보침해신고센터(http://www.cyberprivacy.or.kr, 전화 02-1336, PC통신 go eprivacy), 정보보호마크인증위원회 (www.privacymark.or.kr,02-580-0533),경찰청(www.police.go.kr)에 불만처리를 신청할 수 있습니다.
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section12" className={style.depth1}>
                    타. 개인정보의 취급 위탁 업무
                </div>
                <div className={style.depth2}>
                    회사는 서비스의 제공을 위하여 이용자들의 개인정보를 외부전문업체에 위탁하여 처리할 수 있습니다.<br />
                    개인정보의 취급 위탁은 개인정보취급방침에 명시한 범위내에서 이용하며, 개인정보의 취급 위탁 업체는 다음과 같습니다.<br />
                    1. 인트라밴 : 솔루션 상품 및 웹 사이트의 고객유치, 고객관리 및 가설, A/S 등 유지보수
                </div>
            </div>
            <div className={style.wrapperDepth}>
                <div id="section13" className={style.depth1}>
                    파. 제 3자에 대한 제공
                </div>
                <div className={style.depth2}>
                    회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다.<br />
                    아래의 경우는 예외로 합니다.<br />
                    1.이용자들이 사전에 동의한 경우<br />
                    2.법령의 규정에 의거하거나,수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br />
                    3.만약 이용자의 개인정보를 위 예외사항에 해당되지 않는 목적으로 제공하여야 할 사유가 발생하였을 경우, 고객에게 통지(이메일,웹사이트에 공지 등)하고 반드시 동의를 받도록 하겠습니다.
                </div>
            </div>
            <div id="section14" className={style.wrapperDepth}>
                <div className={style.depth1}>
                    하. 개인정보 관리책임 부서 및 연락처
                </div>
                <div className={style.depth2}>
                    회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만 및 문의사항을 처리하기 위하여 아래와 같이 관련 부서를 지정하여 운영하고 있습니다. 또한 고객의 의견을 매우 소중하게 생각합니다.<br />
                    고객께서 문의사항이 있을 경우 회사의 지사를 방문하시거나 콜센터 또는 개인정보담당부서로 문 의하시면 신속.정확한 답변을 드리겠습니다.
                </div>
                <div className={style.depth3}>
                    1. 개인정보 관리책임 부서 및 연락처<br />
                    가. 기업IT사업본부 스마트솔루션사업담당 소현섭 매니저<br />
                    ㆍ연락처 : 02-424-1901
                </div>
                <div className={style.depth3}>
                    2. 개인정보 관리책임자 역할<br />
                    가. 개인정보보호 관련 주요 지침/정책/제도 수립 시행<br />
                    나. 개인정보 유출, 오남용 방지를 위한 내부통제시스템 구축<br />
                    다. 개인정보보호 분야 관리실태 감사 및 지도점검<br />
                    라. 개인정보보호 교육 계획 수립 시행<br />
                    마. 개인정보취급방침 수립 개정<br />
                    바. 개인정보 침해사고 예방 대응<br />
                    사. 기타 기타정보보호를 위하여 필요한 업무
                </div>
                <div className={style.depth3}>
                    3. 무기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br />
                    가. 개인정보침해신고센터 : http://www.118.or.kr / 118<br />
                    나. 대검찰청 사이버 범죄수사단 : http://www.spo.go.kr / 02-3480-3571 / cybercid@spo.go.kr<br />
                    다. 경찰청 사이버테러대응센터 : http://www.netan.go.kr / 02-393-9112
                </div>
                <div className={style.depth2}>
                    서비스를 적용하여 개인정보의 누출과 훼손을 방지하는 등 서비스 안정화에 노력하고 있습니다.<br />
                    또한 (주)인트라밴 하이웨이서비스는 개인정보보호에 관련된 고객 여러분들의 의견을 수렴하고 있으며 불만을 처리하기 위하여 모 든 절차와 방법을 마련하고 있습니다.아래의 (주)인트라밴 하이웨이서비스 개인정보 관리책임자를 참고하시어 E-Mail을 통하여 고 객 여러분의 의견, 불만사항을 말씀하여 주시면 신속하게 충분히 답변해 드리겠습니다.<br />
                    (주)인트라밴 하이웨이서비스는 개인정보를 수집한 목적을 달성한 때에는 당해 개인정보를 지체없이 파기하며 (주)인트라밴 하이웨이서 비스를 통하여 개인정보를 공유하는 사업자에 대하여도 해당사안에 대하여만 활용을 하도록 하며 이후훼손을 방지하는 등 서 비스 안정화에 노력하고 있습니다.
                </div>
            </div>
        </>
    )
}